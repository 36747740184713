import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import InnerHTML from 'dangerously-set-html-content';
import { Elements } from '@stripe/react-stripe-js';
//properties
import { properties } from '../assets/properties';
//components
import ResponseStripeIframe from './ResponseStripeIframe';


function Response({ responsedata, errorMsg, reference, stripePromise }) {
    const [isElementLoading, setIsElementLoading] = useState(true);

    const [clientSecret, setClientSecret] = useState("");
    const [captchaAction, setCaptchaAction] = useState("");
    const [isTemplate, setIsTemplate] = useState();
    const [template, setTemplate] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [postCode, setPostCode] = useState("");
    const [country, setCountry] = useState("");
    const [returnUrl, setReturnUrl] = useState("");
    const [intentId, setIntentId] = useState("");
    const [region, setRegion] = useState("");
    const [currency, setCurrency] = useState("");
    const [locale, setLocale] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [email, setEmail] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [phone, setPhone] = useState("");
    const [paymentReference, setPaymentReference] = useState("");
    const [isFutureUsageSet, setFutureUsage] = useState(false);
    const [paypalClientToken, setPaypalClientToken] = useState("");
    const [intentType, setintentType] = useState("");
    const [amount, setAmount] = useState("");
    const [paypalCustomerId, setPaypalCustomerId] = useState("");

    useEffect(() => {
        const { template, appShortName, clientSecret, addressLine1, addressLine2, postCode, country, returnUrl, intentId, region, currency, locale, city, state, email, customerName, phone, paymentReference, setupFutureUsage, amount, paypalClientToken, intentType, paypalCustomerId } = responsedata || {};

        setClientSecret(clientSecret);
        setCaptchaAction(appShortName);
        setTemplate(template);
        setIsTemplate(template);
        setAddressLine1(addressLine1);
        setAddressLine2(addressLine2);
        setPostCode(postCode);
        setCountry(country);
        setReturnUrl(returnUrl);
        setIntentId(intentId);
        setRegion(region);
        setCurrency(currency);
        setLocale(locale);
        setCity(city);
        setState(state);
        setEmail(email);
        setCustomerName(customerName);
        setPhone(phone);
        setPaymentReference(paymentReference);
        setFutureUsage(setupFutureUsage);
        setAmount(amount);
        setPaypalClientToken(paypalClientToken);
        setintentType(intentType);
        setPaypalCustomerId(paypalCustomerId);
    }, [])

    useEffect(() => {
        const options = {
            clientSecret,
            appearance: { theme: properties.theme },
            locale: template ? "auto" : locale
        };

        if (!errorMsg) {
            const targetElement = document.getElementById("wpg-iframe");
            if (targetElement) {
                const elementsProps = {
                    captchaAction: captchaAction,
                    addressLine1: addressLine1,
                    addressLine2: addressLine2,
                    postCode: postCode,
                    country: country,
                    returnUrl: returnUrl,
                    intentId: intentId,
                    region: region,
                    currency: currency,
                    city: city,
                    state: state,
                    email: email,
                    customerName: customerName,
                    phone: phone,
                    paymentReference: paymentReference,
                    isFutureUsageSet: isFutureUsageSet,
                    paypalClientToken: paypalClientToken,
                    intentType: intentType,
                    amount: amount,
                    paypalCustomerId: paypalCustomerId,
                    setIsElementLoading: setIsElementLoading
                }
                if (template && clientSecret) {
                    elementsProps.isTemplate = isTemplate;
                }
                if (!template && clientSecret) {
                    elementsProps.reference = reference
                    elementsProps.locale = locale
                }
                clientSecret && ReactDOM.render(
                    <Elements stripe={stripePromise} options={options}>
                        {isElementLoading && <div id='loadingDiv'></div>}
                        <ResponseStripeIframe {...elementsProps} />
                    </Elements>,
                    targetElement)
            }
        }
    }, [template && clientSecret, isElementLoading]);

    return (
        <div>
            {!errorMsg ? (
                template ?
                    (<div><InnerHTML html={template} /></div>) : (<div id="wpg-iframe"></div>)
            ) : (<div className="errorMsg">{errorMsg}</div>)}
        </div>
    )
}

export default Response;