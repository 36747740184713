import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import InnerHTML from 'dangerously-set-html-content';
import Response from './components/Response';
import { properties } from './assets/properties';
import './App.css';
import './css/template.css';
import { env } from './env';

function App() {
  //setting up the state variables
  const [stripePromise, setStripePromise] = useState();
  const [isJSONResponse, setIsJSONResponse] = useState(true);
  const [responsedata, setResponseData] = useState();
  const [clientSecret, setClientSecret] = useState("");
  const [reference, setReference] = useState();
  const [errorMsg, setErrorMsg] = useState();
  var referenceParam;

  useEffect(() => {
    const getClientSecret = async () => {
      try {
        if (window.location.pathname === '/') {
          var request = {
            method: properties.methodList.GET,
            headers: new Headers({ 'Content-type': properties.contentType.formUrlEncoded },),
            credentials: "include"
          }
          let baseURL = (window !== window.parent) ? env.REACT_APP_INLINE_BASE_URL : env.REACT_APP_BASE_URL;
          if (window !== window.parent) {
            let urlParam = new URLSearchParams(window.location.search);
            referenceParam = urlParam.get("reference");
            setReference(referenceParam);
          }
          fetch(baseURL + (properties.apiList.templateURL + (window !== window.parent ? ("?ref=" + referenceParam) : "")), request, true)
            .then(response => {
              const contentType = response.headers.get("content-type");
              if (contentType && contentType.indexOf(properties.contentType.json) !== -1) {
                return response.json().then(responsedata => {
                  if (responsedata.returnCode) {
                    setErrorMsg(responsedata.returnMessage);
                  }
                  else {
                    setClientSecret(responsedata.clientSecret);
                    setResponseData(responsedata);
                    setStripePromise(loadStripe(responsedata.publishableKey));
                    setIsJSONResponse(true);
                  }
                });
              } else {
                return response.text().then(responsedata => {
                  setIsJSONResponse(false);
                  setResponseData(responsedata);
                });
              }
            });
        }
        else {
          const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret');
          setClientSecret(clientSecret);
        }
      } catch (e) {
        console.log('Error Occurs');
        console.log(e);
      }
    }
    // fetching the ClientSecret and Template
    getClientSecret();
  }, []);


  if (isJSONResponse) {
    return (
      <div className="App">
        {(clientSecret && responsedata && stripePromise) && (
            <Router>
              <Routes>
                <Route path="/" element={<Response responsedata={responsedata} errorMsg={null} reference={reference} stripePromise={stripePromise} />} />
              </Routes>
            </Router>
        )}
        {errorMsg && (
          <Router>
            <Routes>
              <Route path="/" element={<Response responsedata={responsedata} errorMsg={errorMsg} />} />
            </Routes>
          </Router>
        )}
      </div>
    );
  }
  else {
    return (<div><InnerHTML html={responsedata} /></div>)
  }
}

export default App;